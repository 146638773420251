// Import
import { CaloudiContentMaskComponent } from './caloudi-content-mask/caloudi-content-mask.component';
import { CaloudiDonutChartComponent } from './caloudi-donut-chart/caloudi-donut-chart.component';
import { CaloudiGaugeChartComponent } from './caloudi-gauge-chart/caloudi-gauge-chart.component';
import { CaloudiReportSenderComponent } from './caloudi-report-sender/caloudi-report-sender.component';
import { CaloudiReportTopbarComponent } from './caloudi-report-topbar/caloudi-report-topbar.component';
import { ChartComponent } from './chart/chart.component';
import { ColorBoxComponent } from './color-box/color-box.component';
import { CommonPeriodSelectorComponent } from './common-period-selector/common-period-selector.component';
import { Copy2ClipboardComponent } from './copy2clipboard/copy2clipboard.component';
import { CronGenComponent } from './cron-editor/cron-editor.component';
import { TimePickerComponent } from './cron-editor/cron-time-picker.component';
import { CustomPeriodSelectorComponent } from './custom-period-selector/custom-period-selector.component';
import { FaIconComponent } from './fa-icon/fa-icon.component';
import { JsonFormatterComponent } from './json-formatter/json-formatter.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { ParkingScheduleCellComponent } from './parking-schedule-editor/parking-schedule-cell/parking-schedule-cell.component';
import { ParkingScheduleEditorComponent } from './parking-schedule-editor/parking-schedule-editor.component';
import { PivotTableComponent } from './pivot-table/pivot-table.component';
import { PrimeChipComponent } from './prime-chip/prime-chip.component';
import { PrimeDialogComponent } from './prime-dialog/prime-dialog.component';
import { PrimeTableComponent } from './prime-table/prime-table.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { ReportDeliveryComponent } from './report-delivery/report-delivery.component';
import { ShareLinkDialogComponent } from './share-link-dialog/share-link-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { UninitializedComponent } from './uninitialized/uninitialized.component';
import { WebChatBotComponent } from './webchat-bot/webchat-bot.component';
// D3
import * as D3Charts from './caloudi-d3-charts';

// Export
export * from './caloudi-content-mask/caloudi-content-mask.component';
export * from './caloudi-d3-charts';
export * from './caloudi-donut-chart/caloudi-donut-chart.component';
export * from './caloudi-formly/caloudi-formly.component';
export * from './caloudi-gauge-chart/caloudi-gauge-chart.component';
export * from './caloudi-report-sender/caloudi-report-sender.component';
export * from './caloudi-report-topbar/caloudi-report-topbar.component';
export * from './chart/chart.component';
export * from './color-box/color-box.component';
export * from './common-period-selector/common-period-selector.component';
export * from './copy2clipboard/copy2clipboard.component';
export * from './cron-editor/cron-editor.component';
export * from './cron-editor/cron-time-picker.component';
export * from './custom-period-selector/custom-period-selector.component';
export * from './fa-icon/fa-icon.component';
export * from './json-formatter/json-formatter.component';
export * from './notification-dialog/notification-dialog.component';
export * from './parking-schedule-editor/parking-schedule-cell/parking-schedule-cell.component';
export * from './parking-schedule-editor/parking-schedule-editor.component';
export * from './pivot-table/pivot-table.component';
export * from './prime-chip/prime-chip.component';
export * from './prime-dialog/prime-dialog.component';
export * from './prime-table/prime-table.component';
export * from './read-more/read-more.component';
export * from './report-delivery/report-delivery.component';
export * from './share-link-dialog/share-link-dialog.component';
export * from './spinner/spinner.component';
export * from './uninitialized/uninitialized.component';
export * from './webchat-bot/webchat-bot.component';

export const CUSTOM_COMPONENTS = [
  CaloudiContentMaskComponent,
  CaloudiDonutChartComponent,
  CaloudiGaugeChartComponent,
  CaloudiReportSenderComponent,
  CaloudiReportTopbarComponent,
  ChartComponent,
  ColorBoxComponent,
  CommonPeriodSelectorComponent,
  Copy2ClipboardComponent,
  CronGenComponent,
  CustomPeriodSelectorComponent,
  FaIconComponent,
  JsonFormatterComponent,
  NotificationDialogComponent,
  ParkingScheduleCellComponent,
  ParkingScheduleEditorComponent,
  PivotTableComponent,
  ReportDeliveryComponent,
  ShareLinkDialogComponent,
  SpinnerComponent,
  TimePickerComponent,
  UninitializedComponent,
  WebChatBotComponent,
  ReadMoreComponent,
];

export const PRIME_NG_COMPONENTS = [
  PrimeTableComponent,
  PrimeChipComponent,
  PrimeDialogComponent,
];

export const D3_CHARTS_COMPONENTS = [
  D3Charts.AnomalyLinechartComponent,
  D3Charts.ForecastLinechartComponent,
  D3Charts.ForceDirectedGraphComponent,
  D3Charts.LinechartComponent,
  D3Charts.MultivariateAnomalyChartComponent,
  D3Charts.RatingBarChartComponent,
  D3Charts.StackBarChartComponent,
  D3Charts.MultiDonutChartComponent,
];
